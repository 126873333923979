.company-icon{
    font-size: 70pt;
}
.school-icon{
    font-size: 70pt;
}
.social{
    font-size: 20pt;
}
.company-icon{
    font-size: 70pt;
}
.github-icon{
    font-size: 70pt;
}

.all-repositories{
    background-color: gainsboro !important;
}
.company-icon{
    font-size: 70pt;
}
